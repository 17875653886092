import React, { useEffect, useState } from "react";
import i18next from "i18next";
import { useHooks } from "hooks";

import "./style.scss";
import { Logo } from "assets/images";
import { ArrowIcon, Avatar, Notification } from "assets/images/icons";
import { Link } from "react-router-dom";
import { Checkbox, Form, notification, Radio, Spin, Tooltip } from "antd";


const HeaderComponent = () => {
  const { t } = useHooks();

  return (
    <header className="header">
      <Link to={"/"}>
        <img src={Logo} alt="" />
      </Link>
      <div className="header_right">
        <Link className="header_link" to={"/notification"}>
          <Notification />
          <span>14</span>
        </Link>
        <Link className="header_link" to={"/profile"}>
          <div>
            <Avatar />
          </div>
        </Link>
        <Tooltip title={t("Выкл/Вкл веб-сайт")}>
          <Checkbox />
        </Tooltip>
      </div>
    </header>
  );
};

export default HeaderComponent;
