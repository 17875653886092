import { lazy } from "react";
const Home = lazy(() => import("pages/home"));
const Categories = lazy(() => import("pages/categories"));
const AddCategory = lazy(() => import("pages/categories/AddCategory"));
const EditCategory = lazy(() => import("pages/categories/EditCategory"));
const CategoriesLayout = lazy(() =>
  import("pages/categories/CategoriesLayout")
);
const Subcategories = lazy(() => import("pages/subcategories"));
const AddSubcategory = lazy(() => import("pages/subcategories/AddSubcategory"));
const EditSubcategory = lazy(() =>
  import("pages/subcategories/EditSubcategory")
);
const SubcategoriesLayout = lazy(() =>
  import("pages/subcategories/SubcategoriesLayout")
);
const Innercategories = lazy(() => import("pages/innercategories"));
const AddInnercategory = lazy(() =>
  import("pages/innercategories/AddInnercategory")
);
const EditInnercategory = lazy(() =>
  import("pages/innercategories/EditInnercategory")
);
const InnerCategoriesLayout = lazy(() =>
  import("pages/innercategories/InnercategoriesLayout")
);
const Brands = lazy(() => import("pages/brands"));
const AddBrand = lazy(() => import("pages/brands/AddBrand"));
const EditBrand = lazy(() => import("pages/brands/EditBrand"));
const BrandsLayout = lazy(() => import("pages/brands/BrandsLayout"));
const Colors = lazy(() => import("pages/colors"));
const AddColor = lazy(() => import("pages/colors/AddColor"));
const EditColor = lazy(() => import("pages/colors/EditColor"));
const ColorsLayout = lazy(() => import("pages/colors/ColorsLayout"));
const Infos = lazy(() => import("pages/infos"));
const AddInfo = lazy(() => import("pages/infos/AddInfo"));
const EditInfo = lazy(() => import("pages/infos/EditInfo"));
const InfosLayout = lazy(() => import("pages/infos/InfosLayout"));
const Users = lazy(() => import("pages/users"));
const Balance = lazy(() => import("pages/users/Balance"));

const User = lazy(() => import("pages/users/User"));
const AddUser = lazy(() => import("pages/users/AddUser"));
const UsersLayout = lazy(() => import("pages/users/UsersLayout"));
const ShoppingGuidesLayout = lazy(() =>
  import("pages/shoppingGuides/ShoppingGuidesLayout")
);
const ShoppingGuides = lazy(() => import("pages/shoppingGuides"));
const AddShoppingGuide = lazy(() =>
  import("pages/shoppingGuides/AddShoppingGuide")
);
const EditShoppingGuide = lazy(() =>
  import("pages/shoppingGuides/EditShoppingGuide")
);
const SolutionsLayout = lazy(() => import("pages/solutions/SolutionsLayout"));
const Solutions = lazy(() => import("pages/solutions"));
const AddSolutions = lazy(() => import("pages/solutions/AddSolution"));
const EditSolution = lazy(() => import("pages/solutions/EditSolution"));
const InspirationsLayout = lazy(() => import("pages/collections/CollectionsLayout"));
const Inspirations = lazy(() => import("pages/collections"));
const AddInspirations = lazy(() => import("pages/collections/AddCollection"));
const EditInspiration = lazy(() => import("pages/collections/EditCollection"));
const ProductsLAyout = lazy(() => import("pages/products/ProductsLayout"));
const Products = lazy(() => import("pages/products"));
const AddProduct = lazy(() => import("pages/products/AddProduct"));
const EditProduct = lazy(() => import("pages/products/EditProduct"));
const Advantages = lazy(() => import("pages/advantages"));
const AddAdvantage = lazy(() => import("pages/advantages/AddAdvantage"));
const EditAdvantage = lazy(() => import("pages/advantages/EditAdvantage"));
const AdvantagesLayout = lazy(() =>
  import("pages/advantages/AdvantagesLayout")
);
const Directions = lazy(() => import("pages/directions"));
const AddDirection = lazy(() => import("pages/directions/AddDirection"));
const EditDirection = lazy(() => import("pages/directions/EditDirection"));
const DirectionsLayout = lazy(() =>
  import("pages/directions/DirectionsLayout")
);
const Rules = lazy(() => import("pages/rules"));
const EditRules = lazy(() => import("pages/rules/EditRules"));
const RulesLayout = lazy(() => import("pages/rules/RulesLayout"));
const Links = lazy(() => import("pages/links"));
const EditLinks = lazy(() => import("pages/links/EditLinks"));
const LinksLayout = lazy(() => import("pages/links/LinksLayout"));

const NotFound = lazy(() => import("pages/notFound"));

// const Login = lazy(() => import("pages/login"));

export interface IRoute {
  path: string;
  key?: string | "*";
  element: JSX.Element;
  inner?: IRoute[];
  index?: boolean;
  title: string;
}

const privateRoutes: IRoute[] = [
  {
    path: "/",
    key: "Home",
    title: "Home",
    element: <Home />,
  },
  {
    path: "/categories",
    key: "CategoriesLayout",
    title: "Categories Layout",
    element: <CategoriesLayout />,
    inner: [
      {
        path: "",
        key: "Categories",
        title: "Categories",
        element: <Categories />,
      },
      {
        path: "add",
        key: "AddCategory",
        title: "Add Category",
        element: <AddCategory />,
      },
      {
        path: "edit/:id",
        key: "EditCategory",
        title: "Edit Category",
        element: <EditCategory />,
      },
    ],
  },
  {
    path: "/advantages",
    key: "AdvantagesLayout",
    title: "Advantages Layout",
    element: <AdvantagesLayout />,
    inner: [
      {
        path: "",
        key: "Advantages",
        title: "Advantages",
        element: <Advantages />,
      },
      {
        path: "add",
        key: "AddAdvantage",
        title: "Add Advantage",
        element: <AddAdvantage />,
      },
      {
        path: "edit/:id",
        key: "EditAdvantage",
        title: "Edit Advantage",
        element: <EditAdvantage />,
      },
    ],
  },
  {
    path: "/rules",
    key: "RulesLayout",
    title: "Rules Layout",
    element: <RulesLayout />,
    inner: [
      {
        path: "",
        key: "Rules",
        title: "Rules",
        element: <Rules />,
      },
      {
        path: "edit",
        key: "EditRules",
        title: "Edit Rules",
        element: <EditRules />,
      },
    ],
  },
  {
    path: "/links",
    key: "LinksLayout",
    title: "Links Layout",
    element: <LinksLayout />,
    inner: [
      {
        path: "",
        key: "Links",
        title: "Links",
        element: <Links />,
      },
      {
        path: "edit",
        key: "EditLinks",
        title: "EditLinks",
        element: <EditLinks />,
      },
    ],
  },
  {
    path: "/subcategories",
    key: "SubcategoriesLayout",
    title: "Subcategories Layout",
    element: <SubcategoriesLayout />,
    inner: [
      {
        path: "",
        key: "Subcategories",
        title: "Subcategories",
        element: <Subcategories />,
      },
      {
        path: "add",
        key: "AddSubcategory",
        title: "Add Subcategory",
        element: <AddSubcategory />,
      },
      {
        path: "edit/:id",
        key: "EditSubcategory",
        title: "Edit Subcategory",
        element: <EditSubcategory />,
      },
    ],
  },
  {
    path: "/innercategories",
    key: "InnerCategoriesLayout",
    title: "InnerCategoriesLayout",
    element: <InnerCategoriesLayout />,
    inner: [
      {
        path: "",
        key: "InnerCategories",
        title: "InnerCategories",
        element: <Innercategories />,
      },
      {
        path: "add",
        key: "AddInnercategory",
        title: "Add Innercategory",
        element: <AddInnercategory />,
      },
      {
        path: "edit/:id",
        key: "EditInnercategory",
        title: "Edit Innercategory",
        element: <EditInnercategory />,
      },
    ],
  },
  {
    path: "/colors",
    key: "ColorsLayout",
    title: "Colors Layout",
    element: <ColorsLayout />,
    inner: [
      {
        path: "",
        key: "Colors",
        title: "Colors",
        element: <Colors />,
      },
      {
        path: "add",
        key: "AddColor",
        title: "Add Color",
        element: <AddColor />,
      },
      {
        path: "edit/:id",
        key: "EditColor",
        title: "Edit Color",
        element: <EditColor />,
      },
    ],
  },
  {
    path: "/infos",
    key: "InfosLayout",
    title: "Infos Layout",
    element: <InfosLayout />,
    inner: [
      {
        path: "",
        key: "Infos",
        title: "Infos",
        element: <Infos />,
      },
      {
        path: "add",
        key: "AddInfo",
        title: "Add Info",
        element: <AddInfo />,
      },
      {
        path: "edit/:id",
        key: "Edit Info",
        title: "Edit Info",
        element: <EditInfo />,
      },
    ],
  },
  {
    path: "/brands",
    key: "brandsLayout",
    title: "Brands Layout",
    element: <BrandsLayout />,
    inner: [
      {
        path: "",
        key: "Brands",
        title: "Brands",
        element: <Brands />,
      },
      {
        path: "add",
        key: "AddBrand",
        title: "Add Brand",
        element: <AddBrand />,
      },
      {
        path: "edit/:id",
        key: "EditBrand",
        title: "Edit Brand",
        element: <EditBrand />,
      },
    ],
  },
  {
    path: "/users",
    key: "UsersLayout",
    title: "Users Layout",
    element: <UsersLayout />,
    inner: [
      {
        path: "",
        key: "Users",
        title: "Users",
        element: <Users />,
      },
      {
        path: ":id",
        key: "User",
        title: "User",
        element: <User />,
      },
      {
        path: "add",
        key: "AddUser",
        title: "Add User",
        element: <AddUser />,
      },
      {
        path: "balance",
        key: "balance",
        title: "balance",
        element: <Balance />,
      },
    ],
  },
  {
    path: "/shopping-guides",
    key: "ShoppingGuidesLayout",
    title: "Shopping Guides Layout",
    element: <ShoppingGuidesLayout />,
    inner: [
      {
        path: "",
        key: "ShoppingGuides",
        title: "ShoppingGuides",
        element: <ShoppingGuides />,
      },
      {
        path: "add",
        key: "AddShoppingGuide",
        title: "Add ShoppingGuide",
        element: <AddShoppingGuide />,
      },
      {
        path: "edit/:id",
        key: "EditShoppingGuide",
        title: "Edit ShoppingGuide",
        element: <EditShoppingGuide />,
      },
    ],
  },
  {
    path: "/solutions",
    key: "SolutionsLayout",
    title: "Solutions Layout",
    element: <SolutionsLayout />,
    inner: [
      {
        path: "",
        key: "Solutions",
        title: "Solutions",
        element: <Solutions />,
      },
      {
        path: "add",
        key: "AddSolution",
        title: "EditSolution",
        element: <AddSolutions />,
      },
      {
        path: "edit/:id",
        key: "EditSolution",
        title: "Edit Solution",
        element: <EditSolution />,
      },
    ],
  },
  {
    path: "/directions",
    key: "DirectionsLayout",
    title: "DirectionsLayout",
    element: <DirectionsLayout />,
    inner: [
      {
        path: "",
        key: "Directions",
        title: "Directions",
        element: <Directions />,
      },
      {
        path: "add",
        key: "AddDirection",
        title: "AddDirection",
        element: <AddDirection />,
      },
      {
        path: "edit/:id",
        key: "EditDirection",
        title: "Edit Direction",
        element: <EditDirection />,
      },
    ],
  },
  {
    path: "/collections",
    key: "InspirationsLayout",
    title: "Inspirations Layout",
    element: <InspirationsLayout />,
    inner: [
      {
        path: "",
        key: "Inspirations",
        title: "Inspirations",
        element: <Inspirations />,
      },
      {
        path: "add",
        key: "AddInspiration",
        title: "Add Inspiration",
        element: <AddInspirations />,
      },
      {
        path: "edit/:id",
        key: "EditInspiration",
        title: "Edit Inspiration",
        element: <EditInspiration />,
      },
    ],
  },
  {
    path: "/products",
    key: "ProductsLayout",
    title: "Products Layout",
    element: <ProductsLAyout />,
    inner: [
      {
        path: "",
        key: "Products",
        title: "Products",
        element: <Products />,
      },
      {
        path: "add",
        key: "AddProduct",
        title: "Add Product",
        element: <AddProduct />,
      },
      {
        path: "edit/:id",
        key: "EditProduct",
        title: "Edit Product",
        element: <EditProduct />,
      },
    ],
  },
  {
    path: "*",
    key: "*",
    title: "",
    element: <NotFound />,
  },
];

const publicRoutes: IRoute[] = [
  // {
  //   path: "/login",
  //   access: [],
  //   title: "Login",
  //   element: <Login />,
  // },
];

export { privateRoutes, publicRoutes };
