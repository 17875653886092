import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload, UploadFile, UploadProps, message } from "antd";
import { FieldProps } from "formik";
import axios from "axios";
import { storage } from "services";
//@ts-ignore
type FileType = Parameters<UploadProps["beforeUpload"]>[0];

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

interface Props extends FieldProps<any, any> {
  label?: string;
  className?: string;
  errorMessage?: string | any;
  rootClassName?: string;
  limit: number;
  listType: any;
  onSuccess: () => void;
  hasSuccess: boolean;
}

const App = (props: Props) => {
  const {
    form: { setFieldValue },
    field: { name },
    limit = 5, // Limit for max number of images
    listType,
    onSuccess = () => {},
  } = props;

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    const uploadedUrls = newFileList
      .filter((file) => file.status === "done" && file.url)
      .map((file) => file.url);
    setFieldValue(name, uploadedUrls); // Store URLs array in form field
  };

  const customRequest = async ({ file, onSuccess, onError }: any) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(
        "https://cdn.myproje.uz/api/upload-without-tokens?size=large&format=webp",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200 && response.data?.data?.file_url) {
        const uploadedFile = response.data.data;

        const newFile: UploadFile = {
          uid: uploadedFile.file_id,
          name: uploadedFile.file_name,
          status: "done",
          url: uploadedFile.file_url,
        };
        setFieldValue(name, {id: uploadedFile.file_id, url: uploadedFile.file_url})
        setFileList((prevList) => [...prevList, newFile]);
        message.success("File uploaded successfully");
        onSuccess();
      } else {
        message.error("File upload failed");
        onError(response.statusText || "File upload failed");
      }
    } catch (error) {
      console.error("File upload error:", error);
      message.error("File upload failed");
      onError(error || "File upload failed");
    }
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined />
      <div className="mt-[8px]">Upload</div>
    </button>
  );

  return (
    <>
      <Upload
        listType={listType}
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        customRequest={customRequest}
        multiple
      >
        {fileList.length >= limit ? null : uploadButton}
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default App;
